<template>
  <c-flex
    gap="16px"
    flex-direction="column"
  >
    <ChipFilter
      :options="optionFilters"
      :selected-filter="selectedFilter"
      justify-content="flex-start"
      :min-width="['30px', '40px']"
      @on-change-filter="onChangeFilter"
    />
    <Info
      variant="info"
      text="Pilih salah satu bahan makanan pada setiap golongan makanan. Contoh: Saat sarapan, pilih nasi sebagai makanan pokok, ikan sebagai lauk hewani, bayam sebagai sayur."
    />

    <c-box margin-top="16px">
      <c-flex justify-content="space-between">
        <c-text
          font-size="20px"
          font-weight="600"
          color="primary.400"
        >
          {{ mealTileIdToLabel[parseData[selectedFilter?.id]?.mealTime] }} - {{ parseData[selectedFilter?.id]?.caloriesAmount }}kkal
        </c-text>
        <c-flex
          justify-content="center"
          align-items="center"
          gap="8px"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-clock.svg')"
            height="20px"
            width="20px"
            fill="#333333"
          />
          <c-text
            font-size="16px"
            color="neutral.superDarkGray"
          >
            {{ parseData[selectedFilter?.id]?.startTime }} - {{ parseData[selectedFilter?.id]?.endTime }}
          </c-text>
        </c-flex>
      </c-flex>
      <base-divider color="neutral.superDarkGray" />
    </c-box>

    <AccordionCustom>
      <AccordionCustomItem
        v-for="(item, idx) in parseData[selectedFilter?.id]?.mealComposition"
        :key="idx"
        :label="item?.foodGroup"
        :sub-label="`${item?.portion} porsi`"
        :is-disabled="false"
        default-is-open
      >
        <c-box v-if="item?.mealItems?.length >= 1 || item?.additionalMealItems?.length >= 1">
          <c-text
            font-size="16px"
            color="primary.400"
          >
            Pilihan bahan makanan
          </c-text>

          <c-grid
            margin-top="8px"
            template-columns="repeat(auto-fill, minmax(100px, 1fr))"
            gap="6"
          >
            <c-flex
              v-for="mealItem in item?.mealItems"
              :key="mealItem?.id"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              w="100%"
              min-height="10px"
              margin-bottom="auto"
            >
              <c-image
                v-if="mealItem?.photoUrl"
                :src="mealItem?.photoUrl"
                object-fit="cover"
                size="72px"
                :_hover="{
                  transition: 'transform 0.2s',
                  transform: 'scale(1.25)',
                }"
              />
              <c-text
                font-size="14px"
                text-align="center"
              >
                {{ mealItem?.foodName }}
              </c-text>
              <c-text
                color="neutral.888888"
                font-size="14px"
                text-align="center"
              >
                {{ (mealItem?.householdMeasurementPerPortion || 0.0) * parseFloat(item?.portionDecimal || "0.0") }} {{ mealItem?.householdMeasurementUnitPerPortion }}
              </c-text>
            </c-flex>
            <c-flex
              v-for="mealItem in item?.additionalMealItems"
              :key="mealItem?.id"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              w="100%"
              min-height="10px"
            >
              <c-box
                height="72px"
                width="72px"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-image-preview.svg')"
                  height="72px"
                  width="72px"
                  fill="#008C81"
                />
              </c-box>
              <c-text font-size="14px">
                {{ mealItem?.foodName }}
              </c-text>
              <c-text
                color="neutral.888888"
                font-size="14px"
              >
                {{ (mealItem?.householdMeasurement || 0) }} {{ mealItem?.householdMeasurementUnit }}
              </c-text>
            </c-flex>
          </c-grid>
        </c-box>
        <NoData
          v-else
          text="Tidak ada data"
        />
        <BaseDivider color="neutral.superDarkGray" />
      </AccordionCustomItem>
    </AccordionCustom>

    <c-grid
      w="100%"
      :template-columns="['repeat(2, 1fr)']"
      :gap="['16px']"
    >
      <c-button
        variant="outline"
        variant-color="primary"
        height="60px"
        border-radius="60px"
        font-size="18px"
        :is-disabled="isFirstStep"
        @click="handlePrevious"
      >
        Sebelumnya
      </c-button>
      <c-button
        variant="solid"
        variant-color="primary"
        height="60px"
        border-radius="60px"
        font-size="18px"
        :is-disabled="isLastStep"
        @click="handleNext"
      >
        Selanjutnya
      </c-button>
    </c-grid>
  </c-flex>
</template>

<script>
import { CButton, CFlex, CGrid } from '@chakra-ui/vue'
import Info from '@/components/info.vue'
import ChipFilter from '@/components/chip-filter.vue'
import AccordionCustomItem from '@/components/accordion-custom/item.vue'
import AccordionCustom from '@/components/accordion-custom/index.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import { mealTileIdToLabel } from '@/constants/meal-time'
import NoData from '@/components/no-data.vue'

export default {
  name: 'PreviewMealPlanSchedule',
  components: {
    CButton,
    CGrid,
    NoData,
    BaseDivider,
    AccordionCustom,
    AccordionCustomItem,
    ChipFilter,
    Info,
    CFlex,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selectedFilter: {},
    }
  },
  computed: {
    isFirstStep() {
      return this.activeStepIndex === 0
    },
    isLastStep() {
      return this.activeStepIndex === this.optionFiltersForNavigation.length - 1
    },
    activeStepIndex() {
      return this.optionFiltersForNavigation.findIndex((it) => it.id === this.selectedFilter.id)
    },
    mealTileIdToLabel() {
      return mealTileIdToLabel
    },
    optionFiltersForNavigation() {
      return this.optionFilters.filter((item) => !item.disabled)
    },
    optionFilters() {
      return [
        {
          id: 'breakfast',
          label: 'Sarapan',
          disabled: this.parseData['breakfast']?.isSkipped,
        },
        {
          id: 'morningSnack',
          label: 'Snack Pagi',
          disabled: this.parseData['morningSnack']?.isSkipped,
        },
        {
          id: 'lunch',
          label: 'Makan Siang',
          disabled: this.parseData['lunch']?.isSkipped,
        },
        {
          id: 'afternoonSnack',
          label: 'Snack Siang',
          disabled: this.parseData['afternoonSnack']?.isSkipped,
        },
        {
          id: 'dinner',
          label: 'Makan Malam',
          disabled: this.parseData['dinner']?.isSkipped,
        },
        {
          id: 'nightSnack',
          label: 'Snack Malam',
          disabled: this.parseData['nightSnack']?.isSkipped,
        },
      ]
    },
    parseData() {
      const tes = this.data?.reduce((acc, curr) => {
        if (curr?.mealTime) {
          acc[curr?.mealTime] = curr
        }
        return acc
      }, {})
      return tes
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.query?.mealTime) {
          this.selectedFilter = this.optionFilters.find((item) => item.id === val.query.mealTime)
        } else {
          this.$router.replace({
            ...val,
            query: {
              ...val?.query,
              mealTime: this.optionFilters[0].id,
            },
          })
          this.selectedFilter = this.optionFilters[0]
        }
      },
    },
  },
  methods: {
    async handlePrevious() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          mealTime: this.optionFiltersForNavigation[this.activeStepIndex - 1]?.id,
        },
      })
    },
    async handleNext() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          mealTime: this.optionFiltersForNavigation[this.activeStepIndex + 1]?.id,
        },
      })
    },
    onChangeFilter(char) {
      if (this.selectedFilter?.id === char?.id) return
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route?.query,
          mealTime: char.id,
        },
      })
      this.selectedFilter = char
    },
  },
}
</script>
