var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data6, _vm$data7, _vm$data8, _vm$data9, _vm$data10, _vm$data11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('Tabs', {
    attrs: {
      "active-tab": "meal-plan",
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm.tabs
    }
  }, [_vm.isProgramConsultationBasic ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info', {
    attrs: {
      "variant": "warning",
      "text": "Formulir tidak dapat diubah dan ditambah karena klien menjalankan program Konsultasi Basic",
      "padding": "16px 28px",
      "font-size": "14px"
    }
  })], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('TabsMealPlan', {
    attrs: {
      "tabs": _vm.subTabs
    }
  }), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": (_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.mealPlanSchedules ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": !_vm.isProfilGiziValid || !_vm.isEdited || _vm.isProgramConsultationBasic
    },
    on: {
      "click": function click($event) {
        _vm.isProfilGiziValid && _vm.isEdited && !_vm.isProgramConsultationBasic ? _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.meal-plan-schedule.edit'
        })) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedTab === 'meal-plan' && (_vm$data2 = _vm.data) !== null && _vm$data2 !== void 0 && _vm$data2.mealPlanSchedules ? 'Edit' : _vm.selectedTab === 'meal-plan' && !((_vm$data3 = _vm.data) !== null && _vm$data3 !== void 0 && _vm$data3.mealPlanSchedules) ? 'Tambah' : '') + " " + _vm._s(_vm.selectedTab === 'meal-recommendation' && (_vm$data4 = _vm.data) !== null && _vm$data4 !== void 0 && _vm$data4.mealRecommendations ? 'Edit' : _vm.selectedTab === 'meal-recommendation' && !((_vm$data5 = _vm.data) !== null && _vm$data5 !== void 0 && _vm$data5.mealRecommendations) ? 'Tambah' : '') + " ")]) : _vm._e()], 1), _c('BaseDivider'), _vm.selectedTab === 'meal-plan' && (_vm$data6 = _vm.data) !== null && _vm$data6 !== void 0 && _vm$data6.mealPlanSchedules ? _c('c-box', [_c('PreviewMealPlanSchedule', {
    attrs: {
      "data": (_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.mealPlanSchedules
    }
  })], 1) : _vm.selectedTab === 'meal-plan' && !((_vm$data8 = _vm.data) !== null && _vm$data8 !== void 0 && _vm$data8.mealPlanSchedules) ? _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Rencana makanku belum terisi"
    }
  })], 1) : _vm._e(), _vm.selectedTab === 'meal-recommendation' && (_vm$data9 = _vm.data) !== null && _vm$data9 !== void 0 && _vm$data9.mealRecommendations ? _c('c-box', [_c('PreviewMealRecommendation', {
    attrs: {
      "data": (_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : _vm$data10.mealRecommendations
    }
  })], 1) : _vm.selectedTab === 'meal-recommendation' && !((_vm$data11 = _vm.data) !== null && _vm$data11 !== void 0 && _vm$data11.mealRecommendations) ? _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Anjuran makanku belum terisi"
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }