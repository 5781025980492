<template>
  <c-box>
    <Tabs
      active-tab="meal-plan"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="tabs"
    >
      <c-box
        v-if="isProgramConsultationBasic"
        margin-bottom="16px"
      >
        <Info 
          variant="warning"
          text="Formulir tidak dapat diubah dan ditambah karena klien menjalankan program Konsultasi Basic"
          padding="16px 28px"
          font-size="14px"
        />
      </c-box>
      <c-flex
        align-items="center"
        justify-content="space-between"
      >
        <TabsMealPlan
          :tabs="subTabs"
        />
        <BaseButton
          v-if="isRoleNutritionist"
          :left-svg-icon="data?.mealPlanSchedules ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          :is-disabled="!isProfilGiziValid || !isEdited || isProgramConsultationBasic"
          @click="isProfilGiziValid && isEdited && !isProgramConsultationBasic ? ($router.push({
            ...$route,
            name: 'nutri.meal-plan.meal-plan-schedule.edit',
          })) : ''"
        >
          {{
            selectedTab === 'meal-plan' && data?.mealPlanSchedules
              ? 'Edit'
              : selectedTab === 'meal-plan' && !data?.mealPlanSchedules
                ? 'Tambah'
                : ''
          }}
          {{
            selectedTab === 'meal-recommendation' && data?.mealRecommendations
              ? 'Edit'
              : selectedTab === 'meal-recommendation' && !data?.mealRecommendations
                ? 'Tambah'
                : ''
          }}
        </BaseButton>
      </c-flex>
      <BaseDivider />

      <!--   PreviewMealPlanSchedule   -->
      <c-box v-if="selectedTab === 'meal-plan' && data?.mealPlanSchedules">
        <PreviewMealPlanSchedule :data="data?.mealPlanSchedules" />
      </c-box>
      <c-box
        v-else-if="selectedTab === 'meal-plan' && !data?.mealPlanSchedules"
        margin-top="16px"
      >
        <NoData
          text="Rencana makanku belum terisi"
        />
      </c-box>

      <!--   PreviewMealRecommendation   -->
      <c-box v-if="selectedTab === 'meal-recommendation' && data?.mealRecommendations">
        <PreviewMealRecommendation :data="data?.mealRecommendations" />
      </c-box>
      <c-box
        v-else-if="selectedTab === 'meal-recommendation' && !data?.mealRecommendations"
        margin-top="16px"
      >
        <NoData
          text="Anjuran makanku belum terisi"
        />
      </c-box>
    </Tabs>
  </c-box>
</template>

<script>
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { getFullnameAndTitle } from '@/utils/specific-case-utils'
import NoData from '@/components/no-data.vue'
import {
  tabs,
} from '@/constants/meal-plans'
import { CBox, CFlex } from '@chakra-ui/vue'
import TabsMealPlan from '@/components/meal-plan/_widgets/tab-meal-plan.vue'
import PreviewMealRecommendation
  from '@/components/meal-plan/_widgets/preview-meal-recommendation.vue'
import PreviewMealPlanSchedule
  from '@/components/meal-plan/_widgets/preview-meal-plan-schedule.vue'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import Info from '@/components/info.vue'

export default {
  name: 'MealScheduleContainer',
  components: {
    PreviewMealPlanSchedule,
    PreviewMealRecommendation,
    TabsMealPlan,
    NoData,
    BaseButton,
    BaseDivider,
    Tabs,
    CBox,
    CFlex,
    Info,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Object, null],
      default: null,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    subTabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    constants() {
      return {
        tabs,
      }
    },
    selectedTab() {
      return this.$route.query?.tab || 'meal-plan'
    },
    isProfilGiziValid() {
      const isAllValid = this.isAllFormValid({
        nutritionalConditions: this.data?.nutritionalConditions && this.data?.nutritionalConditions?.validation === 1,
        nutritionalIntake: this.data?.nutritionalIntake && this.data?.nutritionalIntake?.validation === 1,
        bodyConditions: this.data?.bodyConditions && this.data?.bodyConditions?.validation === 1,
        targetAndSuggestions: this.data?.targetAndSuggestions && this.data?.targetAndSuggestions?.validation === 1,
      })

      return isAllValid
    },
  },
  methods: {
    isAllFormValid,
    getFullnameAndTitle,
  },
}
</script>
