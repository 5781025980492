<!-- INFO: support all v-chakra binding property-->

<template>
  <c-text
    as="span"
    position="relative"
    display="block"
    width="100%"
  >
    <c-divider
      v-bind="{
        ...$attrs,
        ...{
          borderColor: color,
          borderBottomWidth: '1px',
        },
      }"
    />
    <c-text
      v-if="text"
      as="span"
      font-family="Roboto"
      font-size="14px"
      font-weight="400"
      position="absolute"
      display="inline-block"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      background-color="white"
      h="5px"
      padding="0 10px"
      color="white"
    >
      {{ text }}
    </c-text>
    <c-text
      v-if="text"
      as="span"
      font-family="Roboto"
      font-size="14px"
      font-weight="400"
      position="absolute"
      display="inline-block"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      color="#C4C4C4"
    >
      {{ text }}
    </c-text>
  </c-text>
</template>

<script>
export default {
  name: 'BaseDivider',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#C4C4C4',
    },
  },
}
</script>
