export const isAllFormValid = ({
  nutritionalConditions,
  nutritionalIntake,
  bodyConditions,
  targetAndSuggestions,
}) => {
  return nutritionalConditions &&
    nutritionalIntake &&
    bodyConditions &&
    targetAndSuggestions
}