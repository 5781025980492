var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-text', {
    attrs: {
      "as": "span",
      "position": "relative",
      "display": "block",
      "width": "100%"
    }
  }, [_c('c-divider', _vm._b({}, 'c-divider', Object.assign({}, _vm.$attrs, {
    borderColor: _vm.color,
    borderBottomWidth: '1px'
  }), false)), _vm.text ? _c('c-text', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-size": "14px",
      "font-weight": "400",
      "position": "absolute",
      "display": "inline-block",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)",
      "background-color": "white",
      "h": "5px",
      "padding": "0 10px",
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]) : _vm._e(), _vm.text ? _c('c-text', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-size": "14px",
      "font-weight": "400",
      "position": "absolute",
      "display": "inline-block",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)",
      "color": "#C4C4C4"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }