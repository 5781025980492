<template>
  <c-box
    width="100%"
  >
    <c-grid
      v-chakra="{
        '>:not(:first-child)': {
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'primary.300',
        },
        '>:first-child': {
          borderTopLeftRadius: '12px !important',
        },
        '>:last-child': {
          borderTopRightRadius: '12px !important',
        },
      }"
      w="100%"
      :template-columns="['repeat(3, 1fr)']"
      align-items="flex-end"
    >
      <c-flex
        v-for="tab in __subTabs"
        :key="tab.value"
        width="100%"
        justify-content="center"
        align-items="center"
        padding="8px 16px"
        font-size="18px"
        font-weight="500"
        :background-color="activeTab === tab.value ? 'primary.300' : 'primary.50'"
        :color="tab.validation !== 1 ? 'danger.400' : (activeTab === tab.value ? '#FFF' : 'primary.400')"
        :height="activeTab === tab.value ? '60px' : '50px'"
        :border-top-left-radius="activeTab === tab.value ? '12px' : '0'"
        :border-top-right-radius="activeTab === tab.value ? '12px' : '0'"
        cursor="pointer"
        @click="onChangeTab(tab.value)"
      >
        <c-flex
          align-items="flex-start"
          gap="6px"
        >
          {{ tab.label }}
          <c-box
            v-if="tab.validation !== 1"
            width="8px"
            height="8px"
            border-radius="8px"
            background-color="danger.400"
          />
        </c-flex>
      </c-flex>
    </c-grid>
  </c-box>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TabsMealPlan',
  props: {
    mode: {
      type: String,
      default: 'detail',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      role: (s) => s.auth.user?.role,
    }),
    activeTab() {
      return this.$route.query?.tab || 'meal-plan'
    },
    __subTabs() {
      if (this.tabs.length === 0) {
        return [
          { label: 'Rencana Makan', value: 'meal-plan', validation: 1 },
          { label: 'Anjuran Makan', value: 'meal-recommendation', validation: 1 },
        ]
      }

      return this.tabs
    },
  },
  methods: {
    onChangeTab(value) {
      switch (value) {
        case 'meal-plan':
          this.$router.push({
            name: this.role === 'nutritionist' ? (this.mode === 'detail' ? 'nutri.meal-plan.meal-plan-schedule.detail' : 'nutri.meal-plan.meal-plan-schedule.edit') : 'admin.clients.meal-plan.meal-plan-schedule',
            params: {
              clientId: this.$route.params.clientId,
              programId: this.$route.params.programId,
              savePosition: true,
            },
            query: {
              tab: 'meal-plan',
            },
          })
          break
        case 'meal-recommendation':
          this.$router.push({
            name: this.role === 'nutritionist' ? (this.mode === 'detail' ? 'nutri.meal-plan.meal-plan-schedule.detail' : 'nutri.meal-plan.meal-plan-schedule.edit') : 'admin.clients.meal-plan.meal-plan-schedule',
            params: {
              clientId: this.$route.params.clientId,
              programId: this.$route.params.programId,
              savePosition: true,
            },
            query: {
              tab: 'meal-recommendation',
            },
          })
          break
      }
    },
  },
}
</script>
