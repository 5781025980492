<template>
  <c-flex
    :background-color="style.softColor"
    :padding="$attrs['padding'] ?? '6px 10px'"
    border-radius="8px"
    align-items="center"
    gap="10px"
  >
    <inline-svg
      :src="require('@/assets/icons/icon-circle-warn.svg')"
      height="20px"
      width="20px"
      min-height="20px"
      min-width="20px"
      :fill="style.colorHex"
    />
    <c-text
      :color="style.color"
      :font-size="$attrs['font-size'] ?? '14px'"
    >
      {{ text }}
    </c-text>
  </c-flex>
</template>

<script>
import { CFlex, CText } from '@chakra-ui/vue'

export default {
  name: 'Info',
  components: { CText, CFlex },
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    text: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    style() {
      switch (this.variant) {
        case 'warning':
          return {
            color: 'warning.400',
            softColor: 'warning.50',
            colorHex: '#DA6D06',
          }
        case 'danger':
          return {
            color: 'danger.400',
            softColor: 'danger.50',
            colorHex: '#D32737',
          }
        case 'info':
          return {
            color: 'info.400',
            softColor: 'info.50',
            colorHex: '#0C72E0',
          }
        default:
          return {
            color: 'primary.400',
            softColor: 'primary.50',
            colorHex: '#008C81',
          }
      }
    },
  },
}
</script>
