<template>
  <c-box>
    <c-flex
      gap="16px"
      flex-direction="column"
    >
      <CardInfoThingsThatNeed
        color="warning"
        label="Yang perlu dibatasi"
      >
        <template #items>
          <c-box
            v-html="data?.limited"
          />
        </template>
      </CardInfoThingsThatNeed>
      <CardInfoThingsThatNeed
        color="danger"
        label="Yang harus dihindari"
      >
        <template #items>
          <c-box
            v-html="data?.avoided"
          />
        </template>
      </CardInfoThingsThatNeed>
    </c-flex>
  </c-box>
</template>

<script>
import { CBox, CFlex } from '@chakra-ui/vue'
import CardInfoThingsThatNeed
  from '@/components/meal-plan/_widgets/card-info-things-that-need.vue'

export default {
  name: 'PreviewMealRecommendation',
  components: { CardInfoThingsThatNeed, CFlex, CBox },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>
