var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "gap": "16px",
      "flex-direction": "column"
    }
  }, [_c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "warning",
      "label": "Yang perlu dibatasi"
    },
    scopedSlots: _vm._u([{
      key: "items",
      fn: function fn() {
        var _vm$data;

        return [_c('c-box', {
          domProps: {
            "innerHTML": _vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.limited)
          }
        })];
      },
      proxy: true
    }])
  }), _c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "danger",
      "label": "Yang harus dihindari"
    },
    scopedSlots: _vm._u([{
      key: "items",
      fn: function fn() {
        var _vm$data2;

        return [_c('c-box', {
          domProps: {
            "innerHTML": _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.avoided)
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }