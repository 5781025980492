var render = function () {
  var _vm$$attrs$padding, _vm$$attrs$fontSize;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "background-color": _vm.style.softColor,
      "padding": (_vm$$attrs$padding = _vm.$attrs['padding']) !== null && _vm$$attrs$padding !== void 0 ? _vm$$attrs$padding : '6px 10px',
      "border-radius": "8px",
      "align-items": "center",
      "gap": "10px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-warn.svg'),
      "height": "20px",
      "width": "20px",
      "min-height": "20px",
      "min-width": "20px",
      "fill": _vm.style.colorHex
    }
  }), _c('c-text', {
    attrs: {
      "color": _vm.style.color,
      "font-size": (_vm$$attrs$fontSize = _vm.$attrs['font-size']) !== null && _vm$$attrs$fontSize !== void 0 ? _vm$$attrs$fontSize : '14px'
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }